.swiper {
  width: 100%;
  height: 900px;
  overflow: hidden;
}

/* .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
} */

.swiper-slide img {
  display: block;
  /* width: 100%;
  height: 100%; */
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}

.hero_section {
  padding-top: 80px;
}
